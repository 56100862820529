import React, { useMemo } from "react"
import { isPast, isWithinInterval, isSameDay, compareAsc, compareDesc } from 'date-fns'
import { set, get } from 'idb-keyval';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Stack,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/core"

import { useForm, Controller } from "react-hook-form";
import { useStaticQuery, graphql } from "gatsby"
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

const url = `${process.env.GATSBY_API_DOMAIN}/.netlify/functions/signup`;

const WebinarChoice = ({ webinars, onChange, value, name }) => {
  return (
    <RadioGroup name={name} onChange={onChange} value={value}>
      <Stack spacing="2">
        { webinars.map(webinar => (
          <Radio
            key={webinar.id}
            value={webinar.id}
            id={webinar.id}
            isChecked={value === webinar.id}
            isDisabled={webinar.isPast}
          >{format(new Date(webinar.start_time), "PPp", { locale: fr })} - <strong>{webinar.topic}</strong></Radio>
        ))}
      </Stack>
    </RadioGroup>
  )
}

const sessions = {
  en: ['86821828935'],
  jp: ['81455847056', '89717658395']
}

const Form = ({ setRegistredSession }) => {
  const { control, register, handleSubmit, errors } = useForm();
  const { t, i18n : { language = 'en' } } = useTranslation();

  const { webinars: { nodes: rawWebinars = [] } } = useStaticQuery(graphql`
    {
      webinars: allWebinar {
        nodes {
          id
          host_id
          start_time
          end_time
          duration
          join_url
          topic
        }
      }
    }
  `)

  const webinars = useMemo(() => rawWebinars.filter(item => (
      !isPast(new Date(item.end_time))
      // && isSameDay(new Date(), new Date(item.start_time))
    ))
    .filter(item => !language || sessions[language].includes(item.id))
    .sort((a, b) => compareAsc(a.start_time, b.start_time))
    .map(item => ({
      ...item,
      //isPast: isPast(new Date(item.end_time)),
      isCurrent: isWithinInterval(new Date(), {
        start: new Date(item.start_time),
        end: new Date(item.end_time)
      }),
    })), [rawWebinars])

  const onSubmit = async data => {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    });

    const responseData = await result.json()

    // set('registredSession', responseData);
    setRegistredSession(responseData);

    /*if (isPast(startTime)) {
      window.location.href = joinUrl;
    }*/
  };

  const webinarId = webinars[0]?.id

  if (!webinarId) {
    return <Text>Les sessions sont maintenant terminées</Text>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack mb="6" spacing="5">
        { false && <FormControl isInvalid={errors.webinar_id}>
          <FormLabel>Session</FormLabel>
          <Controller
            render={props => <WebinarChoice
              {...props}
              webinars={webinars}
            />}
            name="webinar_id"
            control={control}
            defaultValue=""
            rules={{ required: 'Merci de choisir un webinar' }}
          />
          <FormErrorMessage>{ errors.webinar_id?.message}</FormErrorMessage>
        </FormControl> }
        <input type="hidden" name="webinar_id" value={webinarId} ref={register({ required: true })} />
        <FormControl>
          <FormLabel>{ t('form.firstname') }</FormLabel>
          <Input name="first_name" type="text" ref={register({ required: true })} />
        </FormControl>
        <FormControl>
          <FormLabel>{ t('form.lastname') }</FormLabel>
          <Input name="last_name" type="text" ref={register({ required: true })} />
        </FormControl>
        { false &&
          <FormControl>
            <FormLabel>{ t('form.phone') }</FormLabel>
            <Input name="phone" type="tel" ref={register({ required: true })} />
          </FormControl>
        }
        <FormControl>
          <FormLabel>{ t('form.company') }</FormLabel>
          <Input name="org" type="text" ref={register()} />
        </FormControl>
        <FormControl>
          <FormLabel>{ t('form.mail') }</FormLabel>
          <Input name="email" type="email" ref={register({ required: true })} />
        </FormControl>
      </Stack>
      <Button type="submit" colorScheme="blue">{ t('form.join') }</Button>
      
    </form>
  )
}

export default Form
import React, { useState, useEffect, useCallback } from "react"
import { Link as GatsbyLink } from "gatsby"

import Layout from "@components/layout"
import SEO from "@components/seo"
import Help from "@components/help"

import Form from "@components/form"
import Countdown from 'react-countdown';

import { Link, Heading, Text } from "@chakra-ui/layout"
import { Button, Stack, Box , Divider, Center } from "@chakra-ui/core";
import { set, get } from 'idb-keyval';
import { useTranslation } from 'react-i18next';

const joinSession = (session) => () => {
  window.location.href = session.join_url;
}

const installZoom = () => {
  const win = window.open('https://zoom.us/download', '_blank');
  win.focus();
}

const IndexPage = () => {
  const [registredSession, setRegistredSession] = useState(false);
  const [zoomInstalled, setZoomInstalled] = useState(false);
  const { t, i18n } = useTranslation();

  const { language = 'en' } = i18n;

  if (!zoomInstalled) {
    return (
      <Layout>
        <Box borderWidth="1px" borderRadius="lg" p="10" mb="10">
          { language != 'jp' && <Text fontSize="2xl" fontWeight="bold">
            To best enjoy the experience we recommend downloading <Link isExternal href="https://zoom.us/download">the Zoom Application</Link> and come back to this page.<br/>
            You may also join from your web browser.
          </Text> }
          { language == 'jp' && <Text fontSize="2xl" fontWeight="bold">
            初めに会議用の <Link isExternal href="https://zoom.us/download">ミーティング用ZOOM</Link> のアプリケーションをインストールが必要です。インストール後に再度こちらのページにアクセスください。
          </Text> }
          <Center mt="10">
            <Button display="block" my="3" type="submit" colorScheme="blue" onClick={() => installZoom()}>{ t('intro.download') }</Button>
          </Center>
          <Center mb="10">
            <Button display="block" my="3" type="submit" variant="link" onClick={() => setZoomInstalled(true)}>{ t('intro.skip') }</Button>
          </Center>
        </Box>
      </Layout>
    )
  }

  /*
  useEffect(() => {
    get('registredSession').then(setRegistredSession);
  }, [setRegistredSession]);
  */

  if (registredSession) {
    return (
      <Layout>
        <Box borderWidth="1px" borderRadius="lg" p="10" mb="10">
          { language != 'jp' && <Text fontSize="2xl" fontWeight="bold">You are now registered to our { registredSession.topic } Thank you</Text> }
          { language == 'jp' && <Text fontSize="2xl" fontWeight="bold">{ registredSession.topic } 参加が承認されました</Text> }
          <Box mt="10">
            <Button type="submit" colorScheme="blue" onClick={joinSession(registredSession)}>{ t('welcome.join') }</Button>
          </Box>
        </Box>
        <Help />
      </Layout>
    )
  }

  return (
  <Layout>
    <SEO title={ t('seo.form') } />
    <Stack>
      <Text fontSize="2xl">{ t('form.intro') }</Text>
      <Form setRegistredSession={setRegistredSession} />
    </Stack>
  </Layout>
  )
}

export default IndexPage
